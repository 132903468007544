// eslint-disable-next-line import/no-duplicates
import {styled, withTheme} from 'styled-components';
// eslint-disable-next-line import/named, import/no-duplicates
import {DefaultTheme} from 'styled-components';

import React, {useEffect} from 'react';
import {StyledProps} from '../modules/common';
import withTranslation from 'next-translate/withTranslation';
import {I18n} from '../modules/util/i18n/i18n-interface';
import {changeLanguage, getNewLang} from '../modules/util/i18n/i18n';
import {withAnalytics} from '../modules/analytics/analytics';
import {WithAnalytics} from '../lib/analytics/analytics';
import {typographyTitleBodyStyles} from '../modules/common/styles';
import {useGlobalLoadingIndicator} from '../modules/global-indicator-provider/context';
import {getLanguageAwareURL} from '../modules/util/utilities/get-language-aware-url';
import {getAfterLanguageChangeURL} from '../modules/util/i18n/i18n-get-url-after-change';

export interface FooterProps extends StyledProps, WithAnalytics {
    theme?: DefaultTheme;
    i18n: I18n;
    currentPath: string;
    text?: string;
}

const UnstyledChangeLanguageLink = (props: FooterProps): JSX.Element => {
    const {className, analytics, text, currentPath} = props;
    const {t, lang} = props.i18n;
    const {showLoadingIndicator} = useGlobalLoadingIndicator();

    const newLang = getNewLang(lang);

    const initialAfterLangChangedUrl = getLanguageAwareURL(
        currentPath,
        newLang
    );
    const [afterLangChangeUrl, setAfterLangChangedUrl] = React.useState(
        initialAfterLangChangedUrl
    );

    // This hook is just to modify the href link to include the querystring
    // It runs after the page has loaded in the user's browser.
    // Example:
    // The user goes to fonoma.com?utm_source=google
    // When the page renders (in the server), afterLangChangeUrl
    // equals 'fonoma.com/en' because this page is rendered at build
    // time and then it's cached in cloudflare.
    // When the page arrives to the user's browser, the hook bellow
    // runs and updates afterLangChangeUrl to 'fonoma.com/en?utm_source=google.
    useEffect(() => {
        setAfterLangChangedUrl(
            getAfterLanguageChangeURL(window.location.href, newLang)
        );
    }, [newLang]);

    const onClick = (): void => {
        showLoadingIndicator();
        void changeLanguage(analytics, lang);
    };

    return (
        <a href={afterLangChangeUrl} className={className} onClick={onClick}>
            {text ? text : t('footer:change-language')}
        </a>
    );
};

const StyledChangeLanguageLink = styled(UnstyledChangeLanguageLink)`
    text-decoration: none;
    background: transparent;
    border: 0;
    padding: 0;
    ${typographyTitleBodyStyles}
    text-decoration-style: solid;
    text-decoration-color: ${(props): string => props.theme.colors.ballena};

    &:hover {
        cursor: pointer;
    }

    &:focus {
        border: 0;
    }

    &:focus {
        outline-style: none;
    }
`;

const StyledChangeLanguageLinkNavBar = styled(StyledChangeLanguageLink)`
    border-radius: 24px;
    border: 1px solid ${(props): string => props.theme.colors.tiburon};
    background-color: ${(props): string => props.theme.colors.arroz};
    padding: 8px 16px;
`;

const StyledChangeLanguageLinkHomeFooter = styled(StyledChangeLanguageLink)`
    color: ${(props): string => props.theme.colors.ballena};
`;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const ChangeLanguageLink = withAnalytics(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    withTranslation(withTheme(StyledChangeLanguageLink))
);

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const ChangeLanguageLinkNavBar = withAnalytics(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    withTranslation(withTheme(StyledChangeLanguageLinkNavBar))
);

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const ChangeLanguageLinkHomeFooter = withAnalytics(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    withTranslation(withTheme(StyledChangeLanguageLinkHomeFooter))
);

export {
    ChangeLanguageLink,
    ChangeLanguageLinkNavBar,
    ChangeLanguageLinkHomeFooter,
};
