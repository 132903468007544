import {LANGUAGES} from '../constants';

export function getPathWithoutLangSegment(pathname: string): string {
    const removedPath = pathname
        .replace(`/${LANGUAGES.SPANISH}`, '')
        .replace(`/${LANGUAGES.ENGLISH}`, '');
    if (removedPath === '') {
        return '/';
    }
    return removedPath;
}
