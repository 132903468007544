import {mediaQueries, StyledProps} from '../modules/common';
import {styled} from 'styled-components';
import Image from 'next/image';
import homeBannerDesktop from '../images/home_banner/home_banner_desktop_v2.webp';
import homeBannerMobile from '../images/home_banner/home_banner_mobile_v2.webp';
import homeAcqDesktop from '../images/home_banner/home-landing_Acquisition_desktop.webp';
import homeAcqMobile from '../images/home_banner/home-landing_Acquisition_mobile.webp';

const Noise = styled.div`
    margin-top: -${(props): string => `${props.theme.desktopMeasures.navBarHeight}${props.theme.unitsOfMeasure.px}`};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(
        100% +
            ${(props): string =>
                `${props.theme.desktopMeasures.navBarHeight}${props.theme.unitsOfMeasure.px}`}
    );
    background: url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='5' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")
        0 0/250px auto;
    pointer-events: none;
    filter: contrast(200%) brightness(1000%);
    opacity: 0.35;
`;

const BaseGradient = styled.div`
    width: 100%;
`;

const AcquisitionGradient = styled(BaseGradient)`
    margin-top: -${(props): string => `${props.theme.desktopMeasures.navBarHeight}${props.theme.unitsOfMeasure.px}`};

    background: radial-gradient(
            55% 100% at 50% -35%,
            rgba(255, 90, 155, 1) 10%,
            rgba(90, 166, 255, 1) 55%,
            rgba(255, 255, 255, 0) 90%
        ),
        radial-gradient(
            30% 30% at 50% 112%,
            rgba(48, 194, 237, 0.6) 0%,
            rgba(255, 255, 255, 0) 80%
        );
    ${mediaQueries.lessThan('small')`
        background: 
                radial-gradient(
                    90% 100% at 70% -25%, 
                    rgba(255, 90, 155, 1) 0%, 
                    rgba(90, 166, 255, 9) 40%, 
                    rgba(255, 255, 255, 0) 80%
                ),
                radial-gradient(
                    85% 35% at 50% 112%, 
                    rgba(48, 194, 237, .6) 0%, 
                    rgba(255, 255, 255, 0) 80%
                );
    `};
`;

const RetentionGradient = styled(BaseGradient)`
    margin-top: -${(props): string => `${props.theme.desktopMeasures.navBarHeight}${props.theme.unitsOfMeasure.px}`};

    background: radial-gradient(
            55% 100% at 50% -25%,
            rgba(114, 206, 238, 0.9) 35%,
            rgba(92, 221, 197, 0.4) 65%,
            rgba(255, 255, 255, 0) 100%
        ),
        radial-gradient(
            30% 30% at 50% 105%,
            rgba(255, 90, 155, 0.4) 0%,
            rgba(255, 214, 181, 0.2) 65%,
            rgba(255, 255, 255, 0) 80%
        );
    ${mediaQueries.lessThan('small')`
        background: radial-gradient(
                90% 100% at 70% -25%, 
                rgba(114, 206, 238, 1) 35%, 
                rgba(92, 221, 197, .4) 60%, 
                rgba(255, 255, 255, 0) 80%
            ),
            radial-gradient(
                85% 35% at 50% 112%, 
                rgba(255, 90, 155, .6) 0%, 
                rgba(255, 214, 181, .2) 65%, 
                rgba(255, 255, 255, 0) 80%
            );
    
    `};
`;

const HomeGradient = styled(BaseGradient)`
    margin-top: -${(props): string => `${props.theme.desktopMeasures.navBarHeight}${props.theme.unitsOfMeasure.px}`};

    background: radial-gradient(
            55% 100% at 50% 0%,
            rgb(90, 166, 255, 1) 0%,
            rgba(163, 90, 255, 0.25) 55%,
            rgba(255, 255, 255, 0) 80%
        ),
        radial-gradient(
            30% 30% at 50% 112%,
            rgb(97, 246, 255) 0%,
            rgba(255, 255, 255, 0) 80%
        );

    ${mediaQueries.lessThan('small')`
        background: radial-gradient(
                100% 45% at 70% 0%, 
                rgba(90, 166, 255, 1) 0%, 
                rgba(163, 90, 255, 0.15) 70%, 
                rgba(255, 255, 255, 0) 80%
            ),
            radial-gradient(
                50% 35% at 50% 110%, 
                rgba(97, 246, 255, 1) 0%, 
                rgba(255, 255, 255, 0) 80%
            );   
    `};
`;

const TeloaHomeGradientWrapper = styled(BaseGradient)`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const TeloaHomeAcqGradientWrapper = styled(BaseGradient)`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const HomeCenterGradient = styled(BaseGradient)`
    background:
        radial-gradient(
            26.77% 25.28% at 49.37% 109.06%,
            #ff5a9b 0%,
            rgba(255, 214, 181, 0) 100%
        ),
        radial-gradient(
                53.26% 75.28% at 50.66% -5.28%,
                #72ceee 0%,
                rgba(92, 221, 197, 0) 100%
            )
            #ffffff;
`;

const AskForRechargeGradient = styled(BaseGradient)`
    margin-top: -${(props): string => `${props.theme.desktopMeasures.navBarHeight}${props.theme.unitsOfMeasure.px}`};

    background: radial-gradient(
            55% 100% at 50% -25%,
            rgba(90, 166, 255, 0.9) 35%,
            rgba(114, 206, 238, 0.4) 65%,
            rgba(255, 255, 255, 0) 100%
        ),
        radial-gradient(
            30% 30% at 50% 105%,
            rgba(163, 90, 255, 0.2) 0%,
            rgba(163, 90, 255, 0.15) 50%,
            rgba(255, 255, 255, 0) 80%
        );

    ${mediaQueries.lessThan('small')`
        background: radial-gradient(
                90% 100% at 70% -25%, 
                rgba(90, 166, 255, 1) 35%, 
                rgba(114, 206, 238, .4) 60%, 
                rgba(255, 255, 255, 0) 80%
            ),
            radial-gradient(
                85% 35% at 50% 112%, 
                rgba(163, 90, 255, .6) 0%, 
                rgba(163, 90, 255, .2) 50%, 
                rgba(255, 255, 255, 0) 80%
            );
    `};
`;

export enum GradientType {
    HomeHeader,
    HomeCenter,
    Acquisition,
    Retention,
    Ask,
    TeloaHomeGradient,
    TeloaHomeAcqGradient,
}

export interface GradientPickerProps {
    gradient: GradientType;
    noise?: boolean;
}

// SVG blur placeholder for green gradient
const svgBlurPlaceholderStr =
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iZ3JhZCIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzVjZGRjNSIgLz48c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzMwYzJlZCIgLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM2MWY2ZmYiIC8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4=';

const DesktopImage = styled(Image)`
    object-fit: cover;
    object-position: center;

    @media (max-width: 768px) {
        display: none;
    }
`;

const MobileImage = styled(Image)`
    object-fit: cover;
    object-position: center;
    display: none;

    @media (max-width: 768px) {
        display: block;
    }
`;

const TeloaHomeImageComponent = (): JSX.Element => {
    return (
        <TeloaHomeGradientWrapper>
            <DesktopImage
                src={homeBannerDesktop}
                alt="Teloa Home Banner"
                fill
                priority
                sizes="(min-width: 769px) 100vw, 1px"
                placeholder="blur"
                blurDataURL={svgBlurPlaceholderStr}
            />
            <MobileImage
                src={homeBannerMobile}
                alt="Teloa Home Banner"
                fill
                sizes="(max-width: 768px) 100vw, 1px"
                placeholder="blur"
                blurDataURL={svgBlurPlaceholderStr}
            />
        </TeloaHomeGradientWrapper>
    );
};

const TeloaHomeAcqImageComponent = (): JSX.Element => {
    return (
        <TeloaHomeAcqGradientWrapper>
            <DesktopImage
                src={homeAcqDesktop}
                alt="Teloa Home Acquisition Banner"
                fill
                sizes="(min-width: 769px) 100vw, 1px"
                placeholder="blur"
                priority
                blurDataURL={svgBlurPlaceholderStr}
            />
            <MobileImage
                src={homeAcqMobile}
                alt="Teloa Home Acquisition Banner"
                fill
                sizes="(max-width: 768px) 100vw, 1px"
                placeholder="blur"
                blurDataURL={svgBlurPlaceholderStr}
            />
        </TeloaHomeAcqGradientWrapper>
    );
};

const GradientPicker = ({gradient}: GradientPickerProps): JSX.Element => {
    switch (gradient) {
        case GradientType.HomeHeader:
            return <HomeGradient />;
        case GradientType.HomeCenter:
            return <HomeCenterGradient />;
        case GradientType.Acquisition:
            return <AcquisitionGradient />;
        case GradientType.Retention:
            return <RetentionGradient />;
        case GradientType.Ask:
            return <AskForRechargeGradient />;
        case GradientType.TeloaHomeGradient:
            return <TeloaHomeImageComponent />;
        case GradientType.TeloaHomeAcqGradient:
            return <TeloaHomeAcqImageComponent />;
    }
};

type NoiseBkgProps = GradientPickerProps & StyledProps;

const BasicNoise = ({
    className,
    gradient,
    noise,
}: NoiseBkgProps): JSX.Element => {
    return (
        <div className={className}>
            {noise ? <Noise /> : <></>}
            {/* choosing the gradient component */}
            <GradientPicker gradient={gradient} />
        </div>
    );
};

const HeaderNoise = styled(BasicNoise)`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export {HeaderNoise};
