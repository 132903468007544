import {getPathWithoutLangSegment} from './get-path-without-lang-segment';

export function getLanguageAwareURL(
    path: string,
    lang: string,
    queryString?: string
): string {
    let langAwarePath: string;
    if (lang !== process.env.NEXT_PUBLIC_DEFAULT_LANG) {
        if (path.startsWith('/')) {
            path = path.slice(1);
        }
        langAwarePath = `/${lang}/${path}`;
    } else {
        langAwarePath = getPathWithoutLangSegment(path);
    }
    const newUrl = new URL(
        langAwarePath,
        process.env.NEXT_PUBLIC_SERVER_BASE_URL
    );
    if (queryString) {
        newUrl.search = queryString;
    }
    const urlStr = newUrl.href;
    if (urlStr.endsWith('/')) {
        return urlStr.slice(0, -1);
    }
    return urlStr;
}
