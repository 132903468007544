import React, {ReactNode} from 'react';
import {StyledProps} from '../../modules/common';

export interface SvgProps extends StyledProps {
    href?: string;
    onClick?: () => void;
    children: ReactNode;
    target?: '_self' | '_blank';
    ariaLabel?: string;
}

const ClickableSvg = (props: SvgProps): JSX.Element => {
    const {className, href, onClick, children, target, ariaLabel} = props;
    if (href) {
        return (
            <a
                className={className}
                href={href}
                aria-label={ariaLabel}
                target={target ? target : '_blank'}
                rel="noreferrer"
            >
                {children}
            </a>
        );
    } else if (onClick) {
        return (
            <a
                className={className}
                href="#"
                onClick={onClick}
                target={target ? target : '_blank'}
                rel="noreferrer"
            >
                {children}
            </a>
        );
    } else {
        throw new Error('href or onClick are required');
    }
};

export {ClickableSvg};
