import {updateUserPreferences} from '../cookie-preferences/update-user-preferences';
import {EVENTS, LANGUAGES} from '../constants';
import Cookie from 'universal-cookie';
import {getUserPreferences} from '../cookie-preferences/get-user-preferences';
import {Analytics} from '../../../lib/analytics/analytics';

export const changeLanguage: (
    analytics: Analytics,
    pageLang: string
) => Promise<{previousLang: string; currentLang: string}> = async (
    analytics,
    pageLang
) => {
    const cookies = new Cookie(document.cookie);

    const userPrefs = {...getUserPreferences(cookies)};
    const userPreviousLang = userPrefs.lang;

    const newLang = getNewLang(pageLang);

    // The user previous lang might be equal to the new language in the following scenario
    // 1- The user has Spanish as its language.
    // 2- It goes to phonoma.com/es/promo/some_promo
    // 3- Hit the switch language button.
    if (userPreviousLang !== newLang) {
        userPrefs.lang = newLang;
        updateUserPreferences(cookies, userPrefs);
    }

    await analytics.track(EVENTS.LANGUAGE_CHANGED, {
        previous: pageLang,
        current: newLang,
    });

    return {previousLang: pageLang, currentLang: newLang};
};

export function getNewLang(currentLang: string): string {
    return currentLang === LANGUAGES.ENGLISH
        ? LANGUAGES.SPANISH
        : LANGUAGES.ENGLISH;
}
