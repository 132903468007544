import {PROMO_PATH_MAP} from './promo-path-map';
import {LANGUAGES} from '../constants';
import {getLanguageAwareURL} from '../utilities/get-language-aware-url';

export interface AlternateLang {
    hrefLang: string;
    href: string;
}

/**
 * Returns the array of AlternateLang for the alternatives URLs
 * @param currentLang
 * @param operator
 * @param isAcquisition
 */
export const getLanguageAlternates = (
    operator: string,
    isAcquisition: boolean
): AlternateLang[] => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    return getLanguageAlternatesBase(PROMO_PATH_MAP[operator][isAcquisition]);
};

export function getLanguageAlternatesBase(
    currentPath: string
): AlternateLang[] {
    const alternateLangs: AlternateLang[] = [
        LANGUAGES.SPANISH,
        LANGUAGES.ENGLISH,
    ].map((lang) => ({
        hrefLang: lang,
        href: getLanguageAwareURL(currentPath, lang),
    }));

    alternateLangs.push({
        hrefLang: 'x-default',
        href: getLanguageAwareURL(
            currentPath,
            process.env.NEXT_PUBLIC_DEFAULT_LANG
        ),
    });

    return alternateLangs;
}
