import {RECIPIENT_URL_PARAM, SERVICES} from '../constants';

const SERVER_BASE_URL = process.env.NEXT_PUBLIC_SERVER_BASE_URL;

export const getConnectURLLink = (
    href: string,
    currentLandingURL: string,
    landingName?: string,
    promoCodeId?: string
): string => {
    const baseConnectUrl = '/login';
    const connectURL = new URL(baseConnectUrl, SERVER_BASE_URL);
    const urlToGo = new URL(href);
    const currentURL = new URL(currentLandingURL);
    urlToGo.search = urlToGo.search + currentURL.search.replace('?', '&');
    const finalPartOfTo = currentURL.search
        ? `&${currentURL.search.replace('?', '')}`
        : '';
    connectURL.search = `from=${encodeURIComponent(
        currentLandingURL
    )}&to=${encodeURIComponent(urlToGo.toString())}${finalPartOfTo}`;
    if (landingName) {
        connectURL.search = `${connectURL.search}&ln=${landingName}`;
    }
    if (promoCodeId) {
        connectURL.search = `${connectURL.search}&pcid=${promoCodeId}`;
    }
    return connectURL.toString();
};

type Recipient = {
    phone_number_prefix?: string;
    phone_number_suffix?: string;
    internet_user_id?: string;
};

export const getConnectHomeLandingURLLink = (
    currentLandingURL: string,
    recipient: Recipient,
    serviceName: string,
    serviceProviderName?: string,
    operatorId?: number,
    featureRechargeMe?: boolean
): string => {
    if (serviceName === SERVICES.DATA_PLANS) serviceName = SERVICES.PHONE;
    const baseConnectUrl = '/login';
    const connectURL = new URL(baseConnectUrl, SERVER_BASE_URL);
    const currentURL = new URL(currentLandingURL);

    const baseContactCreateUrl =
        serviceProviderName && operatorId
            ? `${serviceName}/${serviceProviderName}/${operatorId}`
            : ``;
    const contactCreateUrl = new URL(baseContactCreateUrl, SERVER_BASE_URL);

    const fromRecipient =
        serviceName === SERVICES.INTERNET
            ? recipient.internet_user_id
            : recipient.phone_number_suffix;
    currentURL.searchParams.set(RECIPIENT_URL_PARAM, `${fromRecipient}`);
    const fromUrl = currentURL.toString();
    // The recipient parameter of the current URL is no longer necessary
    currentURL.searchParams.delete(RECIPIENT_URL_PARAM);

    const recipientQuery = new URLSearchParams(recipient).toString();

    contactCreateUrl.search = recipientQuery;

    const finalPartOfTo = currentURL.search
        ? `&${currentURL.search.replace('?', '')}`
        : '';

    const suffix = String(recipient.phone_number_suffix).trim();
    const searchParams = new URLSearchParams();

    if (featureRechargeMe) searchParams.append('phone_number_suffix', suffix);
    searchParams.append('from', fromUrl);
    if (!featureRechargeMe && baseContactCreateUrl) {
        const contactCreateURLString =
            contactCreateUrl.toString() + finalPartOfTo;
        searchParams.append('to', contactCreateURLString);
    } else if (finalPartOfTo) {
        const toURL = SERVER_BASE_URL + finalPartOfTo.replace('&', '?');
        searchParams.append('to', toURL);
    }
    connectURL.search = searchParams.toString();

    return connectURL.toString();
};

export const getConnectPromoCodeURLLink = (
    promoCodeId: string,
    currentLandingURL: string
): string => {
    const connectURL = new URL('/login', SERVER_BASE_URL);
    connectURL.search = `?pcid=${promoCodeId}&from=${encodeURIComponent(
        currentLandingURL
    )}`;
    return connectURL.toString();
};
